import React from 'react';

import Block from '../shared/Block';
import HTTP from '../shared/HTTP';
import Helpers from '../shared/Helpers';

const Releases = () => {

    const [releasesState, setReleasesState] = React.useState({
        releases: [],
        loading: false
    })

    React.useEffect(() => {
        setReleasesState(state => ({
            ...state,
            loading: true
        }))
        HTTP.getPluginData({installed: '1.0.0'})
            .then(response => {
                setReleasesState(state => ({
                    ...state,
                    releases: response.sections.changelog
                        .split('<h4>')
                        .filter(e => !!e)
                        .map(e => e.split('</h4>')),
                    loading: false
                }))
            })
    }, [])

    const onDownload = () => {
        // TODO:
        // be sure that below code is prod-ready.
        // only remaining TBD is whether we are going to end up appending version number to download specific version of plugin.
        Helpers.pushEvent('file_download');
        window.location.assign(process.env.REACT_APP_PLUGIN_DOWNLOAD_URL);
    }

    return (
        <>
        {releasesState.loading && (
            "Loading..."
        )}
        {!releasesState.loading && releasesState.releases.map(([version, html], index) => (
            <Block key={index} summary={version} open={index === 0}>
                <div dangerouslySetInnerHTML={{ __html: html }}></div>
                <button 
                    type="button" 
                    className="link accent"
                    onClick={() => onDownload()}>Download</button>
            </Block>
        ))}
        {!releasesState.loading && !releasesState.releases.length && (
            "No releases found."
        )}
        </>
    )
}

export default Releases;