import React from 'react';

import PasswordReset from './PasswordReset';
import HTTP from '../shared/HTTP';

const PasswordRequest = () => {

    const [formState, setFormState] = React.useState({
        values: {
            email: ''
        },
        loading: false,
        submitted: false
    })

    React.useEffect(() => {
        if (formState.values.email) {
            setFormState(state => ({
                ...state,
                valid: true
            }))
        } else {
            setFormState(state => ({
                ...state,
                valid: false
            }))
        }
    }, [formState.values])

    const onChange = (event) => {
        const {name, value} = event.target;
        setFormState(state => ({
            ...state,
            values: {
                ...state.values,
                [name]: value
            }
        }))
    }

    const onSubmit = (event) => {
        event.preventDefault();
        setFormState(state => ({
            ...state,
            loading: true
        }))
        HTTP.forgotPassword({username: formState.values.email})
            .then(() => {
                setFormState(state => ({
                    ...state,
                    loading: false,
                    submitted: true
                }))
            })
    }

    if (formState.submitted) {
        return <PasswordReset credentials={formState.values} />;
    }

    return (
        <>
        <form onSubmit={event => onSubmit(event)}>
            <h2>Forgot password?</h2>
            <p>Enter your email and we'll send you instructions.</p>
            <div className="box">
                <div className="field">
                    <label>Email</label>
                    <input 
                        type="text"
                        name="email"
                        disabled={formState.loading}
                        value={formState.values.email}
                        onChange={event => onChange(event)} />
                </div>
                {/* eslint-disable-next-line */}
                <small>If you forgot the email on your account, <a href="https://trackstarwp.com/contact" target="_blank" rel="noopener">contact us</a>.</small>
                <button 
                    type="submit"
                    className="primary"
                    disabled={formState.loading || !formState.valid}>{formState.loading ? 'Submitting...' : 'Submit'}</button>
            </div>
        </form>

        

        {/* <div style={{marginTop:50}}>
            <pre>{JSON.stringify(formState, null, 2)}</pre>
        </div> */}
        </>
    )
}

export default PasswordRequest;