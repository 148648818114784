import React from 'react';

import HTTP from '../shared/HTTP';
import Helpers from '../shared/Helpers';

const AuthContext = React.createContext(null);

const AuthProvider = (props) => {

    const [cookie, setCookie] = React.useState(Helpers.getCookie('access'));
    const [user, setUser] = React.useState(null);

    React.useEffect(() => {
        if (cookie) {
            HTTP.getUser({access_token: cookie})
                .then(response => {
                    if (!response.errorType) {
                        setUser(Object.fromEntries(response.map(e => [e.Name, e.Value])));
                    } else {
                        // handle possible errors (and probably delete cookie)
                    }
                })
        } else {
            setUser(null);
        }
    }, [cookie])

    const value = React.useMemo(() => ({
        cookie,
        setCookie,
        user
    }), [cookie, setCookie, user]);

    return (
        <AuthContext.Provider value={value}>
            {props.children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => React.useContext(AuthContext);

export default AuthProvider;