import React from 'react';
import { Link, NavLink, Outlet, useLocation } from 'react-router-dom';

import Helpers from '../shared/Helpers';
import { useAuth } from '../providers/AuthProvider';

// import logo from '../images/logo.webp';

const Layout = () => {

    const auth = useAuth();
    const location = useLocation();

    React.useEffect(() => {
        if (auth.cookie) {
            document.body.classList.remove('no-user');
        } else {
            document.body.classList.add('no-user');
        }
    }, [auth.cookie])

    const handleActive = ({isActive}) => {
        return isActive ? 'accent' : '';
    }

    const onLogout = () => {
        Helpers.deleteCookie('access');
        auth.setCookie(null);
    }

    return (
        <>
        <header>
            <div className="sitewidth">
                <Link to="/">
                    <img src="/logo.webp" alt="Trackstar logo" />
                </Link>
                {auth.cookie && (
                    <nav disabled={!auth.user}>
                        <ul>
                            <li>
                                {/* eslint-disable-next-line */}
                                <a href="https://trackstarwp.com/contact" target="_blank" rel="noopener">Contact</a>
                            </li>
                            <li>
                                <button 
                                    type="button" 
                                    className="link"
                                    onClick={() => onLogout()}>Logout</button>
                            </li>
                        </ul>
                    </nav>
                )}
            </div>
        </header>

        <main id={location.pathname.slice(1).toUpperCase()}>
            <div className="sitewidth">
                {auth.cookie && (
                    <nav disabled={!auth.user}>
                        <ul>
                            <li>
                                <NavLink to="/account" className={handleActive}>Account</NavLink>
                            </li>
                            <li>
                                <NavLink to="/billing" className={handleActive}>Billing</NavLink>
                            </li>
                            <li>
                                <NavLink to="/releases" className={handleActive}>Releases</NavLink>
                            </li>
                        </ul>
                    </nav>
                )}
                <section>
                    <Outlet />
                </section>
            </div>
        </main>

        <footer>
            <div className="sitewidth">
                {auth.cookie && (
                    <nav disabled={!auth.user}>
                        <ul>
                            <li>
                                {/* eslint-disable-next-line */}
                                <a href="https://trackstarwp.com/getting-started" target="_blank" rel="noopener">Documentation</a>
                            </li>
                            <li>
                                {/* eslint-disable-next-line */}
                                <a href="https://trackstarwp.com/terms" target="_blank" rel="noopener">Terms</a>
                            </li>
                            <li>
                                {/* eslint-disable-next-line */}
                                <a href="https://trackstarwp.com/privacy" target="_blank" rel="noopener">Privacy</a>
                            </li>
                        </ul>
                    </nav>
                )}
                <span>© {new Date().getFullYear()} Trackstar. All rights reserved.</span>
            </div>
        </footer>
        </>
    )
}

export default Layout;