import React from 'react';

import Block from '../shared/Block';
import { useAuth } from '../providers/AuthProvider';

const Account = () => {
    const auth = useAuth();

    return (
        <Block summary="Account" open={true}>
            <div className="field">
                <label>ID</label>
                <input 
                    type="text" 
                    readOnly={true} 
                    value={auth.user.sub} />
            </div>
            <div className="field">
                <label>Email</label>
                <input 
                    type="text" 
                    readOnly={true} 
                    value={auth.user.email} />
            </div>
        </Block>
    )
}

export default Account;