import React from 'react';

const Table = (props) => {

    const onChangeRow = (event, index) => {
        const {name, value} = event.target;
        props.onChange(name, value, index);
    }

    const onSelectRow = (event, index) => {
        const {checked} = event.target;
        props.onSelected(props.rows.map((e, i) => i !== index ? e : {...e, selected: checked}));
    }

    const onSelectAllRows = (event) => {
        const {checked} = event.target;
        props.onSelected(props.rows.map(e => e.disabled ? e : {...e, selected: checked}));
    }

    const renderHeaderCells = () => {
        switch (props.type) {
            case 'subscriptions':
                return (
                    <>
                    <th>Plan</th>
                    <th>License</th>
                    <th>Domain</th>
                    {/* <th>Created</th> */}
                    {/* <th>Renews</th> */}
                    <th>Status</th>
                    </>
                )
            default:
                return <th>Missing table type.</th>
        }
    }

    const renderDataCells = (row, index) => {
        switch (props.type) {
            case 'subscriptions':
                return (
                    <>
                    <td>{row.plan}</td>
                    <td>
                        <input 
                            type="text" 
                            className="code" 
                            readOnly={true} 
                            value={row.license} />
                    </td>
                    <td>
                        <input 
                            type="text" 
                            name="domain" 
                            placeholder="www.example.com"
                            value={row.domain}
                            onChange={event => onChangeRow(event, index)} />
                    </td>
                    {/* <td>{new Intl.DateTimeFormat('en-US', {year:'numeric', month:'short', day:'2-digit'}).format(new Date(row.created * 1000))}</td> */}
                    {/* <td>{new Intl.DateTimeFormat('en-US', {year:'numeric', month:'short', day:'2-digit'}).format(new Date(row.renews * 1000))}</td> */}
                    <td>
                        {['active', 'trialing'].includes(row.status) && "Active"}
                        {['canceled', 'incomplete_expired'].includes(row.status) && "Inactive"}
                        {['incomplete', 'past_due', 'unpaid'].includes(row.status) && "Unpaid"}
                    </td>
                    </>
                )
            default:
                return <td>Missing table type.</td>
        }
    }

    return (
        <table>
            <tbody>
                <tr>
                    {props.selectable && (
                        <th>
                            <input 
                                type="checkbox" 
                                onChange={event => onSelectAllRows(event)} />
                        </th>
                    )}
                    {renderHeaderCells()}
                </tr>
                {props.loading && (
                    <tr>
                        <td colSpan={100}>Loading...</td>
                    </tr>
                )}
                {!props.loading && props.rows.map((row, index) => (
                    <tr key={index} disabled={row.disabled}>
                        {props.selectable && (
                            <td>
                                <input 
                                    type="checkbox" 
                                    checked={row.selected} 
                                    onChange={event => onSelectRow(event, index)} />
                            </td>
                        )}
                        {renderDataCells(row, index)}
                    </tr>
                ))}
                {!props.loading && !props.rows.length && (
                    <tr>
                        <td colSpan={100}>No rows found.</td>
                    </tr>
                )}
            </tbody>
        </table>
    )
}

export default Table;