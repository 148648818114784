import React from 'react';

const Block = (props) => {
    return (
        <details className="block" open={props.open}>
            <summary>{props.summary}</summary>
            <div>
                {props.children}
            </div>
        </details>
    )
}

export default Block;