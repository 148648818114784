export default class HTTP {

    static request = async (method, url, body) => {
        const options = {
            method: method,
            headers: {'Content-Type':'application/json'},
            body: body ? JSON.stringify(body) : null
        }
        try {
            const response = await fetch(url, options);
            if (!response.ok) {
                throw new Error(response.status);
            } else {
                return response.json();
            }
        } catch (error) {
            console.error(error);
        }
    }

    // User.

    static getUser = async (payload) => {
        return await this.request('POST', 'https://core-api.trackstarwp.com/v1/users/verify-jwt', payload);
    }

    static createUser = async (payload) => {
        return await this.request('POST', 'https://core-api.trackstarwp.com/v1/users/create', payload);
    }

    // static updateUser = async (payload) => {
    //     return await this.request('PUT', '', payload);
    // }

    // static deleteUser = async () => {
    //     return await this.request('DELETE', '');
    // }

    static authenticateUser = async (payload) => {
        return await this.request('POST', 'https://core-api.trackstarwp.com/v1/users/authenticate', payload);
    }

    static verifyCode = async (payload) => {
        return await this.request('POST', 'https://core-api.trackstarwp.com/v1/users/verify-code', payload);
    }

    static forgotPassword = async (payload) => {
        return await this.request('POST', 'https://core-api.trackstarwp.com/v1/users/forgot-password', payload);
    }

    static resetPassword = async (payload) => {
        return await this.request('POST', 'https://core-api.trackstarwp.com/v1/users/verify-pw-reset-code', payload);
    }

    // Stripe.

    static createCheckoutSession = async (payload) => {
        return await this.request('POST', 'https://core-api.trackstarwp.com/v1/stripe/new-session', payload);
    }

    static getCheckoutSession = async (payload) => {
        return await this.request('POST', 'https://core-api.trackstarwp.com/v1/stripe/get-session', payload);
    }

    static createPortalSession = async (payload) => {
        return await this.request('POST', 'https://core-api.trackstarwp.com/v1/stripe/new-portal-session', payload);
    }

    static getSubscriptions = async (payload) => {
        return await this.request('POST', 'https://core-api.trackstarwp.com/v1/stripe/get-subscription', payload);
    }

    static updateSubscriptionMetadata = async (payload) => {
        return await this.request('POST', 'https://core-api.trackstarwp.com/v1/stripe/update-metadata', payload);
    }

    // Misc.

    static getPluginData = async (payload) => {
        return await this.request('POST', 'https://core-api.trackstarwp.com/v1/wordpress/check-version', payload);
    }

}