import React from 'react';
import { useNavigate } from 'react-router-dom';

import Notification from '../shared/Notification';
import HTTP from '../shared/HTTP';

const PasswordReset = (props) => {

    const navigate = useNavigate();

    const [formState, setFormState] = React.useState({
        values: {
            code: '',
            password: '',
            password_confirm: ''
        },
        loading: false
    })
    const [notification, setNotification] = React.useState(null);

    React.useEffect(() => {
        if (formState.values.code && formState.values.password && formState.values.password_confirm && (formState.values.password === formState.values.password_confirm)) {
            setFormState(state => ({
                ...state,
                valid: true
            }))
        } else {
            setFormState(state => ({
                ...state,
                valid: false
            }))
        }
    }, [formState.values])

    const onChange = (event) => {
        const {name, value} = event.target;
        setFormState(state => ({
            ...state,
            values: {
                ...state.values,
                [name]: value
            }
        }))
    }

    const onSubmit = (event) => {
        event.preventDefault();
        setNotification(null);
        setFormState(state => ({
            ...state,
            loading: true
        }))
        HTTP.resetPassword({...formState.values, email: props.credentials.email})
            .then(response => {
                if (!response.errorType) {
                    setNotification({
                        type: 'green',
                        message: 'Your password has been reset. Redirecting to login...'
                    })
                    setTimeout(() => navigate('/login'), 3000);
                } else {
                    setFormState(state => ({
                        ...state,
                        loading: false
                    }))
                    switch (response.errorType) {
                        case 'CodeMismatchException':
                        case 'ExpiredCodeException':
                            setNotification({
                                type: 'red',
                                message: 'Code is incorrect or expired.'
                            })
                            break;
                        case 'InvalidPasswordException':
                            setNotification({
                                type: 'red',
                                message: 'Password is not strong enough.'
                            })
                            break;
                        default: 
                            setNotification({
                                type: 'red',
                                message: 'Something went wrong. Please try again.'
                            })
                    }
                }
            })
    }

    return (
        <>
        <form onSubmit={event => onSubmit(event)}>
            <h2>Reset password.</h2>
            <p>Enter the code we emailed you, then set a new password.</p>

            {notification && (
                <Notification type={notification.type} message={notification.message} />
            )}

            <div className="box">
                <div className="field">
                    <label>Verification code</label>
                    <input 
                        type="text"
                        name="code"
                        disabled={formState.loading}
                        value={formState.values.name}
                        onChange={event => onChange(event)} />
                </div>
                <div className="field">
                    <label>New password</label>
                    <input 
                        type="password"
                        name="password"
                        disabled={formState.loading}
                        value={formState.values.password}
                        onChange={event => onChange(event)} />
                </div>
                <div className="field">
                    <label>Confirm new password</label>
                    <input 
                        type="password"
                        name="password_confirm"
                        disabled={formState.loading}
                        value={formState.values.password_confirm}
                        onChange={event => onChange(event)} />
                </div>
                {/* eslint-disable-next-line */}
                <small>If you have any issues, <a href="https://trackstarwp.com/contact" target="_blank" rel="noopener">contact us</a>.</small>
                <button 
                    type="submit"
                    className="primary"
                    disabled={formState.loading || !formState.valid}>{formState.loading ? 'Submitting...' : 'Submit'}</button>
            </div>
        </form>

        

        {/* <div style={{marginTop:50}}>
            <pre>{JSON.stringify(formState, null, 2)}</pre>
        </div> */}
        </>
    )
}

export default PasswordReset;