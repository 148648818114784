import React from 'react';
import { useSearchParams } from 'react-router-dom';

import Block from '../shared/Block';
import Table from '../shared/Table';
import Notification from '../shared/Notification';
import HTTP from '../shared/HTTP';
import { useAuth } from '../providers/AuthProvider';

const Billing = () => {

    const auth = useAuth();

    const [notifications, setNotifications] = React.useState([]);

    const [subscriptionsState, setSubscriptionsState] = React.useState({
        subscriptions: [],
        loading: false
    })

    React.useEffect(() => {
        setSubscriptionsState(state => ({
            ...state,
            loading: true
        }))
        HTTP.getSubscriptions({customer_email: auth.user.email})
            .then(response => {
                if (response.data) {
                    setSubscriptionsState(state => ({
                        ...state,
                        subscriptions: response.data.map(e => ({
                            plan: e.plan.product.id === process.env.REACT_APP_PRODUCT_BASIC 
                                ? 'Basic' 
                                : e.plan.product.id === process.env.REACT_APP_PRODUCT_PREMIUM 
                                ? 'Premium' 
                                : '',
                            license: e.id,
                            domain: e.metadata.domain ? e.metadata.domain : '',
                            status: e.status,
                        })),
                        loading: false
                    }))
                } else {
                    setSubscriptionsState(state => ({
                        ...state,
                        loading: false
                    }))
                }
            })
    }, [auth.user])

    const onChange = (name, value, index) => {
        setSubscriptionsState(state => ({
            ...state,
            subscriptions: state.subscriptions.map((e, i) => i !== index ? e : {...e, [name]: value, dirty: true})
        }))
    }



    // Checkout functionality. Sample URL for testing: https://app.trackstarwp.com/billing?checkout=true&session_id=cs_test_a1IIFmmyjueG3RdENRlUvssFeowx0NSKvcIibRgxrpn0HOkhssZ2EjuXg7

    const [searchParams] = useSearchParams();

    React.useEffect(() => {
        if (searchParams.get('checkout') === 'true') {
            setNotifications([{
                type: 'yellow',
                message: 'Checking payment status...'
            }])
            HTTP.getCheckoutSession({session_id: searchParams.get('session_id')})
                .then(response => {
                    if (response.payment_status === 'paid') {
                        // TODO:
                        // Decide correct notification message for below. Either link to Getting Started guide, or provide instructions. Ensure user now has access to Releases tab.
                        // Consider: sometimes, new subscription is not available due to eventual consistency, so would be smart to include new subscription ID in message, then make
                        // some mention like "If you dont see your new subscription in the table below, you may need to refresh..."
                        setNotifications([{
                            type: 'green',
                            // message: 'Success! Your subscription has been created. Next, do this...'
                            // message: `Your subscription *${response.subscription.id.slice(-6)} has been created. Next, do this...`
                            // message: <span>Subscription <span className="code" style={{background: '#f0f0f1'}}>{response.subscription.id}</span> has been created.</span>
                            // eslint-disable-next-line
                            message: <span>Success! Your subscription has been created. Next, read our <a className="accent" href="https://trackstarwp.com/getting-started/" target="_blank" rel="noopener">Getting Started</a> guide.</span>
                        }])
                    }
                    if (response.payment_status === 'unpaid') {
                        setNotifications([{
                            type: 'red',
                            message: 'Payment was not successful.'
                        }])
                    }
                })
        }
        if (searchParams.get('checkout') === 'false') {
            setNotifications([{
                type: 'red',
                message: 'Checkout was not successful. Your subscription was not created.'
            }])
        }
    }, [searchParams])



    // Save.

    const [crudding, setCrudding] = React.useState(false);
    const [redirecting, setRedirecting] = React.useState(false);

    const onSave = () => {
        const updated = subscriptionsState.subscriptions.map(e => ({...e, subscription_id: e.license, customer_id: auth.user.sub})).filter(e => e.dirty);
        const message = "Please confirm the domain(s) you entered meet the following formatting requirements before saving.\n\n"
            + "Domain names must be entered exactly as they appear on your website (excluding HTTP/S). "
            + "For example, if you use a subdomain (i.e. www.domain.com), include it here. "
            + "If you don't use one (i.e. domain.com), enter it that way instead.\n\n"
            + `Domain(s) you entered:\n${updated.map(e => e.domain).join('\n')}\n\n`
            + "If your domain(s) look correct, please continue. If you have any questions, please contact us.";
        if (updated.some(e => e.domain) ? window.confirm(message) : true) {
            setCrudding(true);
            Promise.allSettled(updated.map(e => HTTP.updateSubscriptionMetadata(e)))
                .then(results => results.map(e => e.value))
                .then(results => {
                    if (results.every(e => e.id)) {
                        setCrudding(false);
                        setSubscriptionsState(state => ({
                            ...state,
                            subscriptions: state.subscriptions.map(e => ({...e, dirty: false}))
                        }))
                        setNotifications(state => state.concat({
                            type: 'green',
                            message: 'Your subscription(s) have been updated.'
                        }))
                    } else {
                        setNotifications(state => state.concat(
                            results.flatMap((e, i) => e.id ? [] : [{
                                type: 'red',
                                message: 'Subscription could not be updated: ' + updated[i].license
                            }])
                        ))
                    }
                })
        }
    }

    const onManage = () => {
        setRedirecting(true);
        HTTP.createPortalSession({customer_email: auth.user.email})
            .then(response => {
                setRedirecting(false);
                window.location.assign(response.url);
            })
    }



    // Render.

    return (
        <>
        {notifications.map((notification, index) => (
            <Notification 
                key={index} 
                type={notification.type} 
                message={notification.message} />
        ))}

        <Block summary="Subscriptions" open={true}>
            <Table 
                type="subscriptions"
                selectable={false}
                rows={subscriptionsState.subscriptions} 
                loading={subscriptionsState.loading}
                onChange={onChange} />
            <div className="flexbox" style={{marginTop:12}}>
                <button 
                    type="button" 
                    className="primary" 
                    disabled={crudding || !subscriptionsState.subscriptions.some(e => e.dirty)}
                    onClick={() => onSave()}>{crudding ? 'Saving...' : 'Save'}</button>
                {!!subscriptionsState.subscriptions.length && (
                    <button 
                        type="button" 
                        className="secondary marauto" 
                        disabled={redirecting}
                        onClick={() => onManage()}>{redirecting ? 'Redirecting...' : 'Manage subscriptions'}</button>
                )}
            </div>
        </Block>



        {/* <div style={{marginTop:50}}>
            <pre>{JSON.stringify(subscriptionsState, null, 2)}</pre>
        </div> */}
        </>
    )
}

export default Billing;