import React from 'react';
import { useSearchParams } from 'react-router-dom';

import Notification from '../shared/Notification';
import HTTP from '../shared/HTTP';
import Helpers from '../shared/Helpers';

const Verification = (props) => {

    const [searchParams] = useSearchParams();

    const [formState, setFormState] = React.useState({
        values: {
            code: ''
        },
        loading: false
    })
    const [notification, setNotification] = React.useState(null);

    React.useEffect(() => {
        if (formState.values.code) {
            setFormState(state => ({
                ...state,
                valid: true
            }))
        } else {
            setFormState(state => ({
                ...state,
                valid: false
            }))
        }
    }, [formState.values])

    const onChange = (event) => {
        const {name, value} = event.target;
        setFormState(state => ({
            ...state,
            values: {
                ...state.values,
                [name]: value
            }
        }))
    }

    const onSubmit = (event) => {
        event.preventDefault();
        setNotification(null);
        setFormState(state => ({
            ...state,
            loading: true
        }))
        HTTP.verifyCode({code: formState.values.code, email: props.credentials.email})
            .then(response => {
                if (!response.errorType) {
                    HTTP.authenticateUser({...props.credentials, username: props.credentials.email})
                        .then(response2 => {
                            if (!response2.errorType) {
                                Helpers.createCookie('access', response2.AuthenticationResult.AccessToken, 1);
                                Helpers.initCheckoutSession(searchParams, props.credentials.email);
                            } else {
                                setNotification({
                                    type: 'red',
                                    message: 'Something went wrong. Please contact us.'
                                })
                            }
                        })
                } else {
                    setFormState(state => ({
                        ...state,
                        loading: false
                    }))
                    switch (response.errorType) {
                        case 'CodeMismatchException':
                        case 'ExpiredCodeException':
                            setNotification({
                                type: 'red',
                                message: 'Code is incorrect or expired.'
                            })
                            break;
                        default: 
                            setNotification({
                                type: 'red',
                                message: 'Something went wrong. Please try again.'
                            })
                    }
                }
            })
    }

    return (
        <>
        <form onSubmit={event => onSubmit(event)}>
            <h2>Verify.</h2>
            <p>Enter the verification code we emailed you.</p>

            {notification && (
                <Notification type={notification.type} message={notification.message} />
            )}
            
            <div className="box">
                <div className="field">
                    <label>Code</label>
                    <input 
                        type="text"
                        name="code"
                        disabled={formState.loading}
                        value={formState.values.code}
                        onChange={event => onChange(event)} />
                </div>
                {/* eslint-disable-next-line */}
                <small>If you have any issues, <a href="https://trackstarwp.com/contact" target="_blank" rel="noopener">contact us</a>.</small>
                <button 
                    type="submit"
                    className="primary"
                    disabled={formState.loading || !formState.valid}>{formState.loading ? 'Submitting...' : 'Submit'}</button>
            </div>
        </form>

        

        {/* <div style={{marginTop:50}}>
            <pre>{JSON.stringify(formState, null, 2)}</pre>
        </div> */}
        </>
    )
}

export default Verification;