import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate, Outlet, useSearchParams } from 'react-router-dom';

import Layout from './components/Layout';
import Account from './components/Account';
import Billing from './components/Billing';
import Releases from './components/Releases';
import Register from './components/Register';
import Login from './components/Login';
import PasswordRequest from './components/PasswordRequest';
import AuthProvider, { useAuth } from './providers/AuthProvider';
import Helpers from './shared/Helpers';

const CookieGuard = () => {
    const auth = useAuth();

    if (auth.cookie) {
        if (auth.user) {
            return <Outlet />;
        } else {
            return "Loading...";
        }
    } else {
        return <Navigate to="/login" />;
    }
}

const NoCookieGuard = () => {
    const auth = useAuth();

    if (!auth.cookie) {
        return <Outlet />;
    } else {
        return <Navigate to="/account" />;
    }
}

const PlanParamGuard = () => {
    const auth = useAuth();
    const [searchParams] = useSearchParams();

    if (['basic', 'premium'].includes(searchParams.get('plan'))) {
        if (auth.cookie) {
            if (auth.user) {
                Helpers.initCheckoutSession(searchParams, auth.user.email);
            }
            return "Redirecting to checkout...";
        } else {
            return <Outlet />;
        }
    } else {
        window.location.replace('https://trackstarwp.com');
    }
}

ReactDOM.createRoot(document.getElementById('root')).render(
    // <React.StrictMode>
        <BrowserRouter>
            <AuthProvider>
                <Routes>
                    <Route element={<Layout />}>
                        <Route element={<CookieGuard />}>
                            <Route path="/account" element={<Account />} />
                            <Route path="/billing" element={<Billing />} />
                            <Route path="/releases" element={<Releases />} />
                        </Route>
                        <Route element={<NoCookieGuard />}>
                            <Route path="/login" element={<Login />} />
                            <Route path="/password-request" element={<PasswordRequest />} />
                        </Route>
                        <Route element={<PlanParamGuard />}>
                            <Route path="/register" element={<Register />} />
                        </Route>
                    </Route>
                    <Route path="*" element={<Navigate to="/login" />} />
                </Routes>
            </AuthProvider>
        </BrowserRouter>
    // </React.StrictMode>
)