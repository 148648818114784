import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import Notification from '../shared/Notification';
import HTTP from '../shared/HTTP';
import Helpers from '../shared/Helpers';
import { useAuth } from '../providers/AuthProvider';

const Login = () => {

    const auth = useAuth();
    const [searchParams] = useSearchParams();

    const [formState, setFormState] = React.useState({
        values: {
            email: '',
            password: ''
        },
        loading: false
    })
    const [notification, setNotification] = React.useState(null);

    React.useEffect(() => {
        if (formState.values.email && formState.values.password) {
            setFormState(state => ({
                ...state,
                valid: true
            }))
        } else {
            setFormState(state => ({
                ...state,
                valid: false
            }))
        }
    }, [formState.values])

    const onChange = (event) => {
        const {name, value} = event.target;
        setFormState(state => ({
            ...state,
            values: {
                ...state.values,
                [name]: value
            }
        }))
    }

    const onSubmit = (event) => {
        event.preventDefault();
        setNotification(null);
        setFormState(state => ({
            ...state,
            loading: true
        }))
        HTTP.authenticateUser({...formState.values, username: formState.values.email})
            .then(response => {
                if (!response.errorType) {
                    Helpers.pushEvent('login');
                    Helpers.createCookie('access', response.AuthenticationResult.AccessToken, 1);
                    if (searchParams.get('plan')) {
                        Helpers.initCheckoutSession(searchParams, formState.values.email);
                    } else {
                        auth.setCookie(response.AuthenticationResult.AccessToken);
                    }
                } else {
                    setFormState(state => ({
                        ...state,
                        loading: false
                    }))
                    switch (response.errorType) {
                        case 'NotAuthorizedException':
                            setNotification({
                                type: 'red',
                                message: 'Email or password is incorrect.'
                            })
                            break;
                        case 'UserNotConfirmedException':
                            setNotification({
                                type: 'red',
                                message: 'Email not confirmed. Please contact us.'
                            })
                            break;
                        default: 
                            setNotification({
                                type: 'red',
                                message: 'Something went wrong. Please try again.'
                            })
                    }
                }
            })
    }

    return (
        <>
        <form onSubmit={event => onSubmit(event)}>
            <h2>Welcome.</h2>
            {/* eslint-disable-next-line */}
            <p>Login, or <a href="https://trackstarwp.com/#pricing" target="_blank" rel="noopener">choose a plan</a> to get started.</p>

            {notification && (
                <Notification type={notification.type} message={notification.message} />
            )}

            <div className="box">
                <div className="field">
                    <label>Email</label>
                    <input 
                        type="text"
                        name="email"
                        disabled={formState.loading}
                        value={formState.values.email}
                        onChange={event => onChange(event)} />
                </div>
                <div className="field">
                    <label>Password</label>
                    <input 
                        type="password"
                        name="password"
                        disabled={formState.loading}
                        value={formState.values.password}
                        onChange={event => onChange(event)} />
                </div>
                <small>If you forgot your password, <Link to="/password-request">click here</Link>.</small>
                <button 
                    type="submit"
                    className="primary"
                    disabled={formState.loading || !formState.valid}>{formState.loading ? 'Submitting...' : 'Submit'}</button>
            </div>
        </form>



        {/* <div style={{marginTop:50}}>
            <pre>{JSON.stringify(formState, null, 2)}</pre>
        </div> */}
        </>
    )
}

export default Login;