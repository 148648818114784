import HTTP from './HTTP';

export default class Helpers {

    static getCookie = (name) => {
        try {
            for (const cookie of document.cookie.split(';')) {
                if (cookie.trim().startsWith(name + '=')) {
                    return cookie.split('=')[1];
                }
            }
        }
        catch (e) {
            console.error(e);
            // return something
        }
    }

    static createCookie = (name, value, days) => {
        try {
            document.cookie = `${name}=${value}; max-age=${60*60*24*days}`;
        }
        catch (e) {
            console.error(e);
            // return something
        }
    }

    static deleteCookie = (name) => {
        try {
            this.createCookie(name, null, -1);
        }
        catch (e) {
            console.error(e);
            // return something
        }
    }

    static initCheckoutSession = (searchParams, email) => {
        try {
            let price;
            switch (searchParams.get('plan')) {
                case 'basic':
                    price = searchParams.get('annual') ? process.env.REACT_APP_PRICE_BASIC_YEARLY : process.env.REACT_APP_PRICE_BASIC_MONTHLY;
                    break;
                case 'premium':
                    price = searchParams.get('annual') ? process.env.REACT_APP_PRICE_PREMIUM_YEARLY : process.env.REACT_APP_PRICE_PREMIUM_MONTHLY;
                    break;
                default:
                    console.log('No case matched.');
            }
            if (price) {
                HTTP.createCheckoutSession({price: price, customer_email: email})
                    .then(response => {
                        if (response.url) {
                            this.pushEvent('begin_checkout');
                            window.location.replace(response.url);
                        }
                    })
            }
        }
        catch (e) {
            console.error(e);
            // return something
        }
    }

    static pushEvent = (name, args) => {
        try {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: name,
                ...args
            });
        }
        catch (e) {
            console.error(e);
            // return something
        }
    }

}