import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import Verification from './Verification';
import Notification from '../shared/Notification';
import HTTP from '../shared/HTTP';
import Helpers from '../shared/Helpers';

const Register = () => {

    const location = useLocation();

    const [formState, setFormState] = React.useState({
        values: {
            name: '',
            email: '',
            password: '',
            password_confirm: ''
        },
        loading: false,
        submitted: false
    })
    const [notification, setNotification] = React.useState(null);

    React.useEffect(() => {
        if (formState.values.name && formState.values.email && formState.values.password && formState.values.password_confirm && (formState.values.password === formState.values.password_confirm)) {
            setFormState(state => ({
                ...state,
                valid: true
            }))
        } else {
            setFormState(state => ({
                ...state,
                valid: false
            }))
        }
    }, [formState.values])

    const onChange = (event) => {
        const {name, value} = event.target;
        setFormState(state => ({
            ...state,
            values: {
                ...state.values,
                [name]: value
            }
        }))
    }

    const onSubmit = (event) => {
        event.preventDefault();
        setNotification(null);
        setFormState(state => ({
            ...state,
            loading: true
        }))
        HTTP.createUser(formState.values)
            .then(response => {
                if (!response.errorType) {
                    Helpers.pushEvent('sign_up');
                    setFormState(state => ({
                        ...state,
                        loading: false,
                        submitted: true
                    }))
                } else {
                    setFormState(state => ({
                        ...state,
                        loading: false
                    }))
                    switch (response.errorType) {
                        case 'UsernameExistsException':
                            setNotification({
                                type: 'red',
                                message: 'An account with this email already exists.'
                            })
                            break;
                        case 'InvalidParameterException':
                            setNotification({
                                type: 'red',
                                message: 'Email is invalid.'
                            })
                            break;
                        case 'InvalidPasswordException':
                            setNotification({
                                type: 'red',
                                message: 'Password is not strong enough.'
                            })
                            break;
                        default: 
                            setNotification({
                                type: 'red',
                                message: 'Something went wrong. Please try again.'
                            })
                    }
                }
            })
    }

    if (formState.submitted) {
        return <Verification credentials={formState.values} />;
    }

    return (
        <>
        <form onSubmit={event => onSubmit(event)}>
            <h2>Get started.</h2>
            <p>Create an account, or <Link to={`/login${location.search}`}>login</Link> if you have one.</p>

            {notification && (
                <Notification type={notification.type} message={notification.message} />
            )}

            <div className="box">
                <div className="field">
                    <label>Name</label>
                    <input 
                        type="text"
                        name="name"
                        disabled={formState.loading}
                        value={formState.values.name}
                        onChange={event => onChange(event)} />
                </div>
                <div className="field">
                    <label>Email</label>
                    <input 
                        type="text"
                        name="email"
                        disabled={formState.loading}
                        value={formState.values.email}
                        onChange={event => onChange(event)} />
                </div>
                <div className="field">
                    <label>Password</label>
                    <input 
                        type="password"
                        name="password"
                        disabled={formState.loading}
                        value={formState.values.password}
                        onChange={event => onChange(event)} />
                </div>
                <div className="field">
                    <label>Confirm password</label>
                    <input 
                        type="password"
                        name="password_confirm"
                        disabled={formState.loading}
                        value={formState.values.password_confirm}
                        onChange={event => onChange(event)} />
                </div>
                <small>By creating an account, you agree to our terms of service.</small>
                <button 
                    type="submit"
                    className="primary"
                    disabled={formState.loading || !formState.valid}>{formState.loading ? 'Submitting...' : 'Submit'}</button>
            </div>
        </form>

        

        {/* <div style={{marginTop:50}}>
            <pre>{JSON.stringify(formState, null, 2)}</pre>
        </div> */}
        </>
    )
}

export default Register;